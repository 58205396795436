<template>
  <page-template>
    <main-block>
      <block-head :title="$t('Appointment Agents')+' / '" :description="$t('pages.ap_agent_profile.assigned_lead_desc')">
        <template v-slot:title_extras>
          <strong class="text-primary small">{{$t('Assigned Leads')}}</strong>
        </template>
      </block-head>
      <block-content>
        <card no-padding stretch>
          <template v-slot:grouped>
            <div class="card-inner position-relative card-tools-toggle">
              <card-header title="">
                <card-tools>
                  <div class="form-inline flex-nowrap gx-3">
                    <div class="form-wrap w-150px">
                      <select class="form-select form-select-md form-control" v-model="bulkSelectedAction">
                        <option value="">{{ $t('Bulk Action') }}</option>
                        <option value="withdraw">{{ $t('Withdraw') }}</option>
                      </select>
                    </div>
                    <div class="btn-wrap">
                          <span class="d-none d-md-block">
                            <nk-button dim outline type="light" v-on:click="bulkAction" :disabled="!bulkSelectedAction.length">{{ $t('Apply') }}</nk-button>
                          </span>
                      <span class="d-md-none">
                            <nk-button dim outline type="light" v-on:click="bulkAction" is-icon-only-button="" :disabled="!bulkSelectedAction.length">
                              <nio-icon icon="ni-arrow-right"></nio-icon>
                            </nk-button>
                          </span>
                    </div>
                  </div>
                </card-tools>
                <card-tools class="mr-n1">
                  <btn-toolbar>
                    <btn-toolbar-toggle-wrap id="leads_toolbar">
                      <li>
                        <nk-button is-link type=""
                                   is-icon-only-button=""
                                   class="search-toggle"
                                   v-on:click="searchBarActive=true"
                                   :class="{active: searchBarActive}">
                          <nio-icon icon="ni-search"></nio-icon>
                        </nk-button>
                      </li>
                      <li class="btn-toolbar-sep"></li>
                      <li>
                        <drop-down
                            icon="ni-filter-alt"
                            size="lg"
                            direction="right"
                            :dot="( leads.filters.status !== 0  ? 'primary':'')"
                            :title="$t('Filter')">
                          <template v-slot:body>
                            <row class="gx-6 gy-3">
                              <column default="12" md="6">
                                <label class="overline-title overline-title-alt" for="apl_status_filter">{{ $t('Status') }}</label>
                                <select class="form-control form-control-sm" id="apl_status_filter" v-model="leads.filters.status">
                                  <option value="0">{{ $t('All') }}</option>
                                  <option v-for="st in leadStatuses" :value="st.id" :key="'stf'+st.id">{{ $t(st.status_text) }}</option>
                                </select>
                              </column>
                            </row>
                          </template>
                          <template v-slot:footer>
                            <nk-button type="dark" dim v-on:click="getLeads(true)">{{ $t('Filter') }}</nk-button>
                            <a href="javascript:;" v-on:click="[leads.filters.status=0,getLeads(true)]">{{ $t('Reset Filter') }}</a>
                          </template>
                        </drop-down>
                      </li><!-- li -->
                      <li>
                        <drop-down icon="ni ni-setting" title="" size="xs" direction="right">
                          <ul class="link-check">
                            <li><span>{{ $t('Order') }}</span></li>
                            <li :class="{active: leads.order === 'asc'}"><a href="javascript:;" v-on:click="[leads.order='asc', getLeads(true)]">{{ $t('ASC') }}</a></li>
                            <li :class="{active: leads.order === 'desc'}"><a href="javascript:;" v-on:click="[leads.order='desc', getLeads(true)]">{{ $t('DESC') }}</a></li>
                          </ul>
                        </drop-down>
                      </li><!-- li -->
                    </btn-toolbar-toggle-wrap>
                  </btn-toolbar>
                </card-tools>
              </card-header>
              <card-search
                  :placeholder="$t('Search by')+': '+$t('Lead id')+', '+$t('Agent id')+' | '+$t('Name')+' | '+$t('Email')"
                  :is-active="searchBarActive"
                  @do-search="searchRecords"
                  :search-term="searchFilter"
                  @close-search="[searchBarActive=false, searchRecords('')]"></card-search>
            </div>
            <div class="d-flex py-5 justify-center"  v-if="spinners.gettingLeads">
              <ion-spinner></ion-spinner>
            </div>
            <template v-else>
              <div class="card-inner p-0">
                <special-table>
                  <table-row is-heading>
                    <table-col has-check-box>
                      <b-check-box id="asl_select_all" v-model="selectAll"></b-check-box>
                    </table-col>
                    <table-col break-point="md">
                      <span class="sub-text">Lead</span>
                    </table-col>
                    <table-col>
                      <span class="sub-text">{{$t('Assigned to')}}</span>
                    </table-col>
                    <table-col break-point="md">
                      <nio-icon icon="ni-notes-alt" class="mr-1" style="font-size: 1.1rem;" v-tooltip="$t('Notes')"></nio-icon>
                    </table-col>
                    <table-col break-point="md">{{$t('Assign date')}}</table-col>
                    <table-col break-point="lg">{{$t('Opened')}}</table-col>
                    <table-col break-point="lg">{{$t('Current status')}}</table-col>
                    <table-col class="nk-tb-col-tools"></table-col>
                  </table-row>

                  <table-row v-for="lead in leads.data" :key="'ld'+lead.id">
                    <table-col has-check-box>
                      <b-check-box :id="'lead'+lead.id" v-model="lead.selected" :key="'cb'+lead.id"></b-check-box>
                    </table-col>
                    <table-col break-point="md" class="lh-n">
                      <user-small-card
                          :user-name="lead.first_name+' '+lead.last_name"
                          :user-email="lead.email" :image="lead.dp" :initials="lead.initials" bg-color="info">
                        <template v-slot:more_info>
                          <div class="lh-n mt-n1 small">
                            <div class="text-primary">Lead #: {{ lead.lead_number}}</div>
                            <star-rating :rating="parseFloat(lead.rating)" :increment="0.5" :star-size="12" :show-rating="false" :read-only="true"></star-rating>
                          </div>
                        </template>
                      </user-small-card>
                    </table-col>
                    <table-col>
                      <user-small-card
                          :user-name="lead.agent.first_name+' '+lead.agent.last_name"
                          :user-email="lead.agent.email" :image="lead.agent.dp" :initials="lead.agent.initials">
                      </user-small-card>
                    </table-col>
                    <table-col break-point="md">
                      <a href="javascript:;" v-if="lead.notes_count > 0" v-on:click="getLeadNotes(lead.AL_id)" class="d-flex align-items-center">
                        <nio-icon icon="ni-notes-alt" class="mr-1" style="font-size: 1.1rem;"></nio-icon>
                        <span class="text-primary">{{lead.notes_count}}</span>
                      </a>
                    </table-col>
                    <table-col break-point="md">{{ lead.assign_date }}</table-col>
                    <table-col break-point="lg">{{ lead.opened_time }}</table-col>
                    <table-col break-point="lg">{{ $t(lead.status_text) }}</table-col>
                    <table-col class="nk-tb-col-tools">
                      <ul class="nk-tb-actions gx-1">
                        <li>
                          <drop-down-list>
                            <list-item icon="ni-eye" :text="$t('View Details')" :to="{name: 'adminLeadDetails', params:{id:lead.id}}"></list-item>
                            <list-item icon="ni-curve-left-down" :text="$t('Withdraw')" v-on:click="F_withdrawLeads([lead.AL_id])"></list-item>
                          </drop-down-list>
                        </li>
                      </ul>
                    </table-col>
                  </table-row>

                </special-table>
              </div>
              <div class="card-inner" v-if="leads.totalRecords > 10">
                <pagination
                    v-model="leads.page"
                    :records="leads.totalRecords"
                    :per-page="10"
                    @paginate="paginationCallback"
                    theme="bootstrap4"
                    :options="{chunk: 5, texts:{count: '||'}}"/>
              </div>
            </template>
          </template>
        </card>
      </block-content>
    </main-block>
  </page-template>
</template>

<script>
import {
  IonSpinner,
  isPlatform, onIonViewWillEnter
} from '@ionic/vue';
import {computed, defineComponent, reactive, ref, watch} from 'vue';
import {BlockContent, BlockHead, Column, MainBlock, Row} from "@/@core/layouts";
import axios from "@/libs/axios"
import {Card, CardHeader, CardSearch, CardTools} from "@core/components/cards";

import { BtnToolbar, BtnToolbarToggleWrap } from "@core/components/btn-toolbar"
import "bootstrap"
import {DropDown, DropDownList} from "@core/components";

import Pagination from "v-pagination-3"
import {SpecialTable, TableCol, TableRow} from "@core/components/special-table";
import {BCheckBox} from "@core/components/bp-form";
import UserSmallCard from "@/views/components/user-cards/UserSmallCard";
import ListItem from "@core/components/dropdown-list/ListItem";

import StarRating from "vue-star-rating";
import useAdminLeadFunctions from '@core/comp-functions/admin/AdminLeadFunctions'
import {useStore} from "vuex";
import PageTemplate from "@core/layouts/page/PageTemplate";

export default defineComponent({
  components: {
    PageTemplate,
    ListItem,
    DropDownList,
    UserSmallCard,
    BCheckBox,
    SpecialTable,
    TableCol,
    TableRow,
    CardSearch,
    CardTools,
    BlockHead,
    Column,
    Row,
    DropDown,
    BtnToolbar, BtnToolbarToggleWrap,
    CardHeader,
    Card,
    BlockContent,
    MainBlock,
    IonSpinner,
    Pagination,
    StarRating,
  },
  setup() {

    const isDesktop = isPlatform('desktop')
    const {withdrawLeads} = useAdminLeadFunctions()
    const store = useStore()

    let leads = reactive({
      data: [],
      filters:{
        user_filter: 'all',
        status: 0
      },
      order: 'desc',
      page: 1,
      totalRecords: 0,
    })
    let searchFilter  = ref('');

    let spinners = reactive({
      gettingLeads: false,
    })

    let selectAll    = ref(false)
    let searchBarActive = ref(false)

    const paginationCallback = async () => {
      await getLeads(true)
    }

    watch(searchFilter, () => getLeads(true))

    const searchRecords = (search) => {
      searchFilter.value = search
    }

    watch(selectAll, (n) => {
      for(let x in leads.data){
        leads.data[x].selected = n
      }
    })

    let bulkSelectedAction = ref('')
    const bulkAction = async () => {
      let selectedAL_Ids = []
      for(let x in leads.data){
        if( leads.data[x].selected){
          selectedAL_Ids.push( leads.data[x].AL_id)
        }
      }
      if( selectedAL_Ids.length < 1 ){
        return false
      }
      else if( bulkSelectedAction.value === 'withdraw' ){
        await F_withdrawLeads(selectedAL_Ids)
      }
    }

    let cancelToken = false
    const getLeads = () => {
      if(false !== cancelToken){
        cancelToken()
        cancelToken = false
      }
      spinners.gettingLeads = true
      leads.data = []
      axios.post('/api/admin/ap_agent/'+leads.filters.user_filter+'/leads',
          Object.assign({page: leads.page, order: leads.order, search: searchFilter.value}, leads.filters),
          {cancelToken: new axios.CancelToken( c => { cancelToken = c})}
      ).then( resp => {
        if(resp.data.success){
          for(let x in resp.data.data.records){
            let tmp = resp.data.data.records[x]
            tmp.selected = false
            leads.data.push(tmp)
          }
        }
        if(leads.page === 1){
          leads.totalRecords = resp.data.data.total_records
        }
      }).catch(error => {
            console.log(error)
      }).then(()=> {
        cancelToken = false
        spinners.gettingLeads = false
      })
    }
    const F_withdrawLeads = async ( AL_Ids ) => {
      withdrawLeads(AL_Ids, 'lead').then(ids=>{
        // Remove the selected leads from the leads array
        for(let x in ids){
          for(let y in leads.data){
            if(leads.data[y].AL_id === ids[x]){
              leads.data.splice(y,1)
              break;
            }
          }
        }
        // All deleted, no entry left in the data array? and previous pages exists
        if( !leads.data.length && leads.page > 1 ){
          // Deduct from total records value
          leads.totalRecords -= ids.length
          // Goto previous page
          leads.page--
          getLeads(true)
        }
      }).catch(()=>{})
    }
    onIonViewWillEnter(()=>{ getLeads() })
    const leadStatuses = computed(()=>{
      let st = []
      for(let x in store.state.general_data.leads_statuses){
        let tmp = store.state.general_data.leads_statuses[x]
        if(tmp.status_text.toLowerCase() !== 'appointment'){
          st.push(tmp)
        }
      }
      return st
    })


    return {
      isDesktop,

      searchFilter,
      paginationCallback,

      searchBarActive,
      selectAll,

      bulkSelectedAction,
      bulkAction,

      F_withdrawLeads,
      getLeads,
      leads,
      leadStatuses,
      searchRecords,
      spinners,
    }
  }
});

</script>
